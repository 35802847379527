






















































import Vue from 'vue';
import AdminMessageService from '@/services/admin/AdminMessageService';
import MessageStatus from '@/components/messages/MessageStatus.vue';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import { MetaInfo } from 'vue-meta';

class AdminMessageIndexDto {
  id!: string;
  createdAt!: Date;
}

export default Vue.extend({
  components: { MessageStatus, FormatedTimestamp, DataTable },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminMessageIndex.meta.title').toString(),
    };
  },
  data() {
    return {
      Messages: [] as AdminMessageIndexDto[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'CreatedAt', asc: false },
      currentPage: 1,
      loading: false,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminMessageIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      AdminMessageService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.Messages = res.value.messages.items.map((m: AdminMessageIndexDto) => ({
          ...m,
          createdAt: new Date(m.createdAt),
        }));
        this.pages = res.value.messages.totalPages;
        this.setBreadCrumb();
      });
    },
  },
});
